import React from "react"
import "./contactForm.css";
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';

/*Component*/

export default function ContactUs() {

  function sendEmail(e) {
    e.preventDefault();
    const UserID = "user_Ts2K6j2k3RDSurHESLmXo"
    emailjs.sendForm('service_bu1806b', 'form-template', e.target, UserID)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      init("UserID");
      e.target.reset();
  }

  return (
    <form className="contact-form" onSubmit={sendEmail}>
        <div className="form-row">
            <label htmlFor="name">Name:</label>
            <input type="text" name="user_name" id="name" required />
        </div>
        <div className="form-row">
            <label htmlFor="email">Email:</label>
            <input type="email" name="user_email" id="email" required />
        </div>
        <div className="form-row">
            <label htmlFor="message">Message:</label>
            <textarea name="message" id="message" required />
        </div>
        <input type="submit" value="Send" className="submit" />
    </form>
  );
}
