import React from "react";
import { graphql, useStaticQuery } from 'gatsby'
import Layout from "../components/Layout/layout";
import ContactForm from "../components/ContactForm/contactForm";
import '../components/page-styles/pages.css';


/*Component*/

const ContactPage = () => {
    const data = useStaticQuery(graphql`
    query {
        contentfulContactPage {
            title
            description {
                description
            }
        } 
    }
    `)
    const {title, description} = data.contentfulContactPage;
    return (
    <Layout>
        <section className="inner-wrapper">
            <div className="text-wrapper">
                <h1 className="title">{title}</h1>
                <p className="paragraph">{description.description}</p> 
            </div>  
            <ContactForm/>     
        </section>
    </Layout> 
)};

export default ContactPage;